<script setup>
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useAlert } from '../../composables/useAlert';
import { useMoment } from '../../composables/useMoment';
import { getClientSesmt } from '../../services/http-sesmt';
import { useStore } from 'vuex';
import SesmtService from '../../services/SesmtService';
import CardDadosVisita from './components/grid-elaboracao/CardDadosVisita';
import HeaderPanel from './components/HeaderPanel';
import StatusGrid from './components/StatusGrid';
import DialogQuestionarioAdministrativo from './components/grid-elaboracao/DialogQuestionarioAdministrativo.vue';
import StatusGroQuestionariosElaboracaoEnum from '../../enums/StatusGroQuestionariosElaboracao';
import FormRegistrarPendencia from './components/FormRegistrarPendencia.vue';
import DialogAvaliarTrabalhoCampo from './components/grid-elaboracao/DialogAvaliarTrabalhoCampo.vue';

const { currentRoute, push } = useRouter();
const { alertError } = useAlert();
const { formatDateTz } = useMoment();

const records = ref([
    {
        nome: 'inicial',
        produto: 'Questionário administrativo',
        status: null,
        acao: async () => {
            const id = exibirDadosVisita.value.inventarioRiscoVisitaTecnicaId;
            await dialogQuestionarioAdministrativo.value.openDialog(id);
        }
    },
    {
        nome: 'ambiente',
        produto: 'Descrição de ambientes',
        status: null,
        acao: () => {
            const id = exibirDadosVisita.value.inventarioRiscoVisitaTecnicaId;
            const basePath = currentRoute.value.path.endsWith('/') ? currentRoute.value.path : currentRoute.value.path + '/';
            push({ path: `${basePath}ambiente/${id}` });
        }
    },

    {
        nome: 'perigo',
        produto: 'Programa de gerenciamento de risco',
        status: null,
        acao: () => {
            const id = exibirDadosVisita.value.inventarioRiscoVisitaTecnicaId;
            const basePath = currentRoute.value.path.endsWith('/') ? currentRoute.value.path : currentRoute.value.path + '/';

            push({ path: `${basePath}inventario-risco/${id}/` });
        }
    }
]);

const statusQuestionariosElaboracaoOpcoes = ref([]);

const styleMapStatus = {
    [StatusGroQuestionariosElaboracaoEnum.PENDENTE]: {
        backgroundColor: '#F6DFC3',
        color: '#653E11'
    },
    [StatusGroQuestionariosElaboracaoEnum.AGUARDANDO_RETORNO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroQuestionariosElaboracaoEnum.VALIDADO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroQuestionariosElaboracaoEnum.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

const customer = ref({});
const loading = ref(false);
const recordsPerPage = ref(10);
const totalRecords = ref(0);
const currentSortField = ref(null);
const currentSortOrder = ref(null);
const page = ref(1);
const sort = ref(null);
const exibirDadosVisita = ref({});
const dialogQuestionarioAdministrativo = ref();

const getStatusQuestionariosElaboracaoOpcoes = () => {
    statusQuestionariosElaboracaoOpcoes.value = Object.keys(StatusGroQuestionariosElaboracaoEnum).map(function (type) {
        return {
            label: `${StatusGroQuestionariosElaboracaoEnum[type]}`,
            value: `${type}`,
            style: styleMapStatus[StatusGroQuestionariosElaboracaoEnum[type]]
        };
    });
};

const getStatusComTodasAsConfigs = (status) => statusQuestionariosElaboracaoOpcoes.value.find((p) => p.label === status);

const getCustomer = async (customerId) => {
    const { data } = await new SesmtService('/customers').findById(customerId);
    customer.value = data;
};

const load = async () => {
    try {
        loading.value = true;
        const id = currentRoute.value.params.idElaboracao;

        const { data } = await getClientSesmt().get(`aplicacao-pgr-elaboracao/elaborar/${id}`);
        exibirDadosVisita.value.id = data?.id;
        exibirDadosVisita.value.inventarioRiscoVisitaTecnicaId = data?.inventarioRiscoVisitaTecnicaId;
        exibirDadosVisita.value.dataVisita = formatDateTz(data?.inventarioRiscoVisitaTecnica?.dataVisita);
        exibirDadosVisita.value.observacao = data?.inventarioRiscoVisitaTecnica?.observacao;
        exibirDadosVisita.value.avaliador = data?.inventarioRiscoVisitaTecnica?.userCreated.name;
        exibirDadosVisita.value.acompanhante = data?.inventarioRiscoVisitaTecnica?.acompanhante;
        exibirDadosVisita.value.funcaoAcompanhante = data?.inventarioRiscoVisitaTecnica?.funcaoAcompanhante;
        exibirDadosVisita.value.inicio = data?.inventarioRiscoVisitaTecnica?.inicio;
        exibirDadosVisita.value.conclusao = data?.inventarioRiscoVisitaTecnica?.conclusao;
        exibirDadosVisita.value.duracao = data?.inventarioRiscoVisitaTecnica?.duracao;
        exibirDadosVisita.value.statusDadosConclusivos = data?.statusDadosConclusivos;
        adicionarStatusQuestionarios(data.statusQuestionarios);
    } catch (error) {
        const { message } = error?.response?.data;
        alertError(`Erro ao listar! ${message}`);
    } finally {
        loading.value = false;
    }
};

const adicionarStatusQuestionarios = (status) =>
    records.value.find((r) => {
        if (status[r.nome]) {
            r.status = status[r.nome];
        }
    });

const onPage = async (event) => {
    page.value = event.page + 1;
    recordsPerPage.value = event.rows;
    await load();
};

const onSort = async (event) => {
    currentSortField.value = event.sortField;
    currentSortOrder.value = event.sortOrder;
    const field = event.sortField
        ?.split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase()
        ? `${event.sortField
              ?.split(/(?=[A-Z])/)
              .join('_')
              .toLowerCase()}`
        : '';
    const order = event.sortOrder == 1 ? `ASC` : 'DESC';
    if (field) {
        sort.value = {
            [field]: order
        };
    } else {
        sort.value = null;
    }

    await load();
};

const store = useStore();
const init = async () => {
    store.dispatch('setQueryParamsTelaInventarioRiscoGro', {});

    const customerId = currentRoute.value?.params?.id ? Number(currentRoute.value?.params?.id) : 0;
    getStatusQuestionariosElaboracaoOpcoes();
    await getCustomer(customerId);
    await load();
};

onBeforeMount(init);
</script>
<template>
    <div class="card">
        <HeaderPanel tooltip="Listagem de elaboração" :title="'Elaboração'" nomeTelaDoManual="customer-elaboration-gro">
            <template #extraContent>
                <div class="flex flex-row justify-content-center align-items-center gap-2 identificacao-customer">
                    <i class="pi pi-building text-4xl"></i>
                    <div class="flex flex-column">
                        <span class="font-semibold line-height-3 cursor-pointer nome-cliente" v-tooltip.bottom="customer?.name">{{
                            customer?.name
                        }}</span>
                        <span class="">Cód. SOC - {{ customer?.code }}</span>
                    </div>
                </div>
            </template>
        </HeaderPanel>

        <CardDadosVisita
            v-if="exibirDadosVisita?.id"
            :elaboracaoId="exibirDadosVisita?.id"
            :dataVisita="exibirDadosVisita?.dataVisita"
            :observacao="exibirDadosVisita?.observacao"
            :avaliador="exibirDadosVisita?.avaliador"
            :acompanhante="exibirDadosVisita?.acompanhante"
            :funcaoAcompanhante="exibirDadosVisita?.funcaoAcompanhante"
            :inicio="exibirDadosVisita?.inicio"
            :conclusao="exibirDadosVisita?.conclusao"
            :duracao="exibirDadosVisita?.duracao"
            :status="exibirDadosVisita?.statusDadosConclusivos"
            @update:status="($event) => (exibirDadosVisita.statusDadosConclusivos = $event)"
        >
            <template #registrar-pendencia>
                <FormRegistrarPendencia ref="pendencia" :origemId="0" origem="ELABORACAO" @onSuccessPendencia="load" />
            </template>
            <template #avaliar-trabalho-campo>
                <DialogAvaliarTrabalhoCampo />
            </template>
        </CardDadosVisita>

        <DataTable
            dataKey="id"
            :value="records"
            lazy
            :loading="loading"
            loadingIcon="pi pi-spinner"
            :rows="recordsPerPage"
            :totalRecords="totalRecords"
            :rowsPerPageOptions="[10, 50, 100]"
            tableStyle="min-width: 50rem; height: 100%"
            :sortField="currentSortField"
            :sortOrder="currentSortOrder"
            removableSort
            @page="onPage"
            @sort="onSort"
        >
            <Column field="produto" header="Produto" :sortable="true" sortField="product" />
            <Column field="statusElaboration" header="Status" :sortable="true" sortField="customers.status_gro">
                <template #body="{ data }">
                    <StatusGrid v-if="data.status" :status="getStatusComTodasAsConfigs(data.status)" />
                </template>
            </Column>
            <Column style="text-align: right">
                <template #body="{ data }">
                    <Button label="Acessar" class="p-button-link" @click="data.acao(data.inventarioRiscoVisitaTecnicaId)" />
                </template>
            </Column>
        </DataTable>
        <DialogQuestionarioAdministrativo @update:visible="load()" ref="dialogQuestionarioAdministrativo" />
    </div>
</template>
<style scoped lang="scss">
.identificacao-customer {
    padding: 1rem;
    border: solid #ededed 1px;
    border-radius: 4px;
}

.nome-cliente {
    max-width: 25ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
