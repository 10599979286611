<script setup>
import { ref, defineExpose, defineEmits, watch, provide, nextTick } from 'vue';
import FormRegistrarPendencia from '../FormRegistrarPendencia.vue';
import AnexarArquivos from '../AnexarArquivos.vue';
import { useAlert } from '../../../../composables/useAlert.js';
import { getClientSesmt } from '../../../../services/http-sesmt';
import SesmtService from '../../../../services/SesmtService';
import { useRouter } from 'vue-router';
import TipoAnexoEnum from '../../../../enums/TipoAnexoEnum';
import OrigemAnexoEnum from '../../../../enums/OrigemAnexoEnum';
import PerguntasECondicionais from './PerguntasECondicionais.vue';
import { useFocusElement } from '../../../../utils/useFocusElement.js';

const { currentRoute } = useRouter();
const emit = defineEmits(['update:visible']);
const service = new SesmtService(
    `aplicacao-pgr-elaboracao/aplicacao-questionario/elaboracao/${currentRoute.value.params.idElaboracao}/update-by-origem`
);
const { alertError, alertSucess, alertErrorData } = useAlert();
const isOpen = ref(false);
const origemId = ref();
const form = ref([]);
const perguntasComRespostas = ref([]);
const pendencia = ref();
const temPendencia = ref();
const submitted = ref(false);
const loading = ref(false);
const loadingSalvar = ref(false);
const load = async (id) => {
    try {
        loading.value = false;
        const { data: questionario } = await getClientSesmt().get(
            `aplicacao-pgr-elaboracao/aplicacao-questionario/find-by-inventario-risco-visita-tecnica/${id}/origem/INICIAL`
        );

        const { data: respostas } = await getClientSesmt().get(
            `aplicacao-pgr-elaboracao/aplicacao-respostas/origem-id/${questionario.id}/origem/INICIAL/`
        );

        form.value = respostas?.informacoesAplicacaoResposta;
        perguntasComRespostas.value = respostas?.perguntasComRespostas;
        origemId.value = questionario.id;
    } catch (error) {
        const { message } = error?.response?.data || { message };
        alertError(`Erro ao listar! ${message}`);
    } finally {
        loading.value = false;
    }
};
const openDialog = async (id) => {
    await load(id);
    isOpen.value = true;
    submitted.value = false;
};
const closeDialog = () => {
    emit('update:visible');
    isOpen.value = false;
    submitted.value = false;
};
watch(
    () => pendencia.value?.pendencias,
    () => {
        temPendencia.value = pendencia.value?.pendencias.length;
    }
);
const flattenPerguntas = (perguntas) => {
    const result = [];
    const processPergunta = (pergunta, parentResposta) => {
        if (pergunta.respostaCondicional && parentResposta !== pergunta.respostaCondicional) {
            pergunta.resposta = null;
        }

        if (pergunta.respostaCondicional === parentResposta) {
            result.push({
                ...pergunta
            });
        }

        if (pergunta.perguntasVinculadas && pergunta.perguntasVinculadas.length > 0) {
            pergunta.perguntasVinculadas.forEach((vinculada) => {
                processPergunta(vinculada, pergunta.resposta);
            });
        }
    };

    perguntas.forEach((pergunta) => processPergunta(pergunta, null));
    return result;
};

const focusElement = useFocusElement();

const salvar = async () => {
    try {
        submitted.value = true;
        const isValid = validarPerguntasComRespostas();
        if (!isValid) {
            await nextTick();
            focusElement.byId(perguntasSemRespostas.value[0]);
            return;
        }
        loadingSalvar.value = true;
        const perguntasRespostas = flattenPerguntas(perguntasComRespostas.value);
        await service.save({
            id: form.value.origemId,
            origemId: form.value.origemId,
            origem: form.value.origem,
            inventarioRiscoId: form.value.inventarioRiscoId,
            questionarioSesmtId: form.value.questionarioSesmtId,
            etapa: form.value.etapa,
            inserirAnexo: form.value.inserirAnexo,
            anexoObrigatorio: form.value.inserirAnexo,
            respostasConcatenadas: perguntasRespostas.map((item) => `${item.perguntaSesmt.descricao}: ${item.resposta}`).join(' - '),
            perguntasRespostas: perguntasRespostas.map((item) => ({
                ...item
            }))
        });
        closeDialog();
        alertSucess('Questionário salvo com sucesso.');
    } catch (error) {
        if (error?.response?.data?.details?.response?.retornoErrosCadastro?.contemErrosCadastro) {
            closeDialog();
            return;
        }

        alertErrorData(error, 'Não foi possível salvar o questionário, por favor tente novamente.');
    } finally {
        loadingSalvar.value = false;
        submitted.value = false;
    }
};

const validarPerguntasComRespostas = () => {
    const perguntasNiveladas = flattenPerguntas(perguntasComRespostas.value);
    return perguntasNiveladas.filter((pergunta) => pergunta.obrigatorio).every((pergunta) => pergunta.resposta);
};
const perguntasSemRespostas = ref([]);
provide('perguntasSemRespostas', perguntasSemRespostas);

defineExpose({
    closeDialog,
    openDialog
});
</script>
<template>
    <Dialog v-model:visible="isOpen" :style="{ width: '688px', height: 'auto' }" :draggable="false" :modal="true">
        <template #header>
            <h3 style="font-weight: 600">Administrativo</h3>
        </template>

        <div class="flex flex-column align-items-start">
            <div v-for="(item, index) in perguntasComRespostas" :key="index" class="px-2 w-full">
                <PerguntasECondicionais
                    :tipo="item.perguntaSesmt.tipo"
                    :descricao="item.perguntaSesmt.descricao"
                    :opcoes="item.perguntaSesmt.opcoes"
                    :obrigatoria="item.obrigatorio"
                    :perguntasVinculadas="item.perguntasVinculadas"
                    :submitted="submitted"
                    :outros="item?.outros || item?.perguntaSesmt?.outros"
                    v-model="perguntasComRespostas[index].resposta"
                    @update:perguntaCondicional="(newValue) => (item.perguntasVinculadas[index] = newValue)"
                />
            </div>
        </div>

        <div class="w-full mt-6 mb-6">
            <AnexarArquivos :origemId="origemId" :origem="OrigemAnexoEnum.CHECKLIST_INICIAL" :tipo="TipoAnexoEnum.CHECKLIST_INICIAL" />
        </div>
        <Message v-if="temPendencia" class="mb-5" severity="info">Só é possível validar se todas as pendências estiverem concluídas.</Message>
        <FormRegistrarPendencia ref="pendencia" :origemId="origemId" origem="INICIAL" />
        <template #footer>
            <Button label="Cancelar" class="p-button-text justify-content-end" @click="closeDialog()" />
            <Button
                :label="temPendencia ? 'Salvar' : 'Salvar e validar'"
                class="p-button-primary justify-content-end"
                @click="salvar"
                :loading="loadingSalvar"
            />
        </template>
    </Dialog>
</template>

<style>
.p-fileupload .p-button .p-button-icon {
    display: none !important;
}

.botao-com-altura-fixa {
    height: 40px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
